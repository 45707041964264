import React, { useContext, useEffect, useState, useRef } from "react";
import styles from "./Packages.module.css";
import cube from "../../assets/cube-whitebg.png";
import PlansOverviewCard from "../../Components/Community/PlansOverviewCard";
import Context from "../../Context";
import { get } from "../../fetch";
import { toast } from "react-toastify";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';

const Packages = () => {
  const [packs, setPacks] = useState([]);
  const { setLoading, setPageName } = useContext(Context);
  const [selected, setSelected] = useState(0);
  const isMobile = window.innerWidth <= 1276;

  // Create a reference for the Swiper instance
  const swiperRef = useRef(null);

  // Track the current slide index
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    setPageName("Membership Plans");
    setLoading(true);
    get("settings/packages")
      .then((r) => {
        const response = r.data;
        const updatedResponse = Object.keys(response).map((id) => ({
          id,
          ...response[id],
        }));
        setPacks(updatedResponse);
      })
      .catch(() =>
        toast.error(
          "Looks like there's a problem. Reload the page and if this happens again, please come back later."
        )
      )
      .finally(() => setLoading(false));
  }, []);

  // Function to go to the previous slide
  const goToPrevious = () => {
    if (swiperRef.current) swiperRef.current.swiper.slidePrev();
  };

  // Function to go to the next slide
  const goToNext = () => {
    if (swiperRef.current) swiperRef.current.swiper.slideNext();
  };

  // Function to handle slide change
  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex); // Update the current slide index
  };

  return (
    <div className={styles.container}>
      {!isMobile && <img className={styles.img} src={cube} alt="packages" />}
      <div className={styles.content}>
        {isMobile ? (
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            modules={[Pagination]}
            onSnapIndexChange={({ realIndex }) => setSelected(realIndex)}
            onSlideChange={handleSlideChange} // Track slide changes
            className={styles.swiperContainer}
            ref={swiperRef} // Assign the reference to Swiper
          >
            {packs.map((pack) => (
              <SwiperSlide key={pack.pid} className={styles.swiperSlide}>
                <PlansOverviewCard pack={pack} />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          packs.map((pack) => (
            <PlansOverviewCard key={pack.pid} pack={pack} />
          ))
        )}
        {isMobile ? (
          <div className={styles.navigationContainer}>
            <button onClick={goToPrevious} disabled={currentSlide === 0}>
              <FaArrowLeft color="white" />
            </button>
            <button onClick={goToNext} disabled={currentSlide === packs.length - 1}>
              <FaArrowRight color="white" />
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Packages;
