import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get, post } from "../../fetch";
import Context from "../../Context";
import {
  IoCashOutline,
  IoBusinessOutline,
  IoCardOutline,
  IoGiftOutline,
  IoImage,
  IoLogoApple,
} from "react-icons/io5";
import styles from "./PaymentDetails.module.css";
import { toast } from "react-toastify";

const PaymentDetails = ({ type }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setLoading, setPageName } = useContext(Context);
  const [payment, setPayment] = useState({});

    const renderIcon = () => {
      switch (payment.method.toLowerCase()) {
        case "cliq":
          return <IoCashOutline size={30} color="#437689" />;
        case "bank":
          return <IoBusinessOutline size={30} color="#437689" />;
        case "card":
          return <IoCardOutline size={30} color="#437689" />;
        case "gift":
          return <IoGiftOutline size={30} color="#437689" />;
        case "apple":
          return <IoLogoApple size={30} color="#437689" />;
        default:
          return null;
      }
    };

  useEffect(() => {
    setPageName("Payment Details");
    setLoading(true);
    get(`sales/${id}`)
      .then((response) => {
        setPayment(response.data);
      })
      .catch(() => 
        toast.error("There seems to be an error, please come back later.")
      )
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.main}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className={styles.price}>JD {payment?.total}</span>
            <span className={styles.mainDetails}>
              {payment?.package?.name}{payment?.package?.name.toLowerCase() !== "exclusive reward" && " plan fees"}
            </span>
            <span className={styles.mainDetails}>
              {payment?.created}
            </span>
          </div>
          {payment.method ? <div className={styles.listIconContainer}>{renderIcon()}</div> : <></>}
        </div>

        {payment.status && <div className={styles.card}>
          <div className={styles.cardRow}>
            <span>Transaction ID</span>
            <span className={styles.value}>{id}</span>
          </div>
          <div className={styles.cardRow}>
            <span>Plan</span>
            <span className={styles.value}>{payment?.package?.name}</span>
          </div>
          <div className={styles.cardRow}>
            <span>Payment Method</span>
            <span className={styles.value}>{payment?.method}</span>
          </div>
          <div className={styles.cardRow}>
            <span>Status</span>
            <span className={styles.value}>{payment?.status?.charAt(0).toUpperCase() + payment?.status?.slice(1).toLowerCase()}</span>
          </div>
          {payment?.reviewedAt?.length ? <div className={styles.cardRow}>
            <span>Review date</span>
            <span className={styles.value}>{payment?.reviewedAt}</span>
          </div> : <></>}
          {payment?.startDate?.length ? <div className={styles.cardRow}>
            <span>Start date</span>
            <span className={styles.value}>{payment?.startDate}</span>
          </div> : <></>}
          {payment?.expiryDate?.length ? <div className={styles.cardRow}>
            <span>End date</span>
            <span className={styles.value}>{payment?.expiryDate}</span>
          </div> : <></>}
        </div>}

        {payment?.preview?.imageUrl ? <button className={styles.button} onClick={() => window.open(payment?.preview?.imageUrl, "_blank")}>
          <IoImage size={20} color="white" style={{ marginRight: 8 }} />
          View Screenshot
        </button> : <></>}
      </div>
    </div>
  );
};

export default PaymentDetails;
